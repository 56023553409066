<template>
  <div>
    <div>
      <div
        style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        "
      >
        <div style="display: inline-block; width: 60px">
          <img style="width: 100%" src="../../assets/guanghe.png" alt="" />
        </div>
        <div style="display: inline-block; position: absolute; top: 17px">
          <div style="font-size: 18px">光合城晔光小站</div>
          <div>已接订单列表</div>
        </div>
      </div>
      <div class="bianmin">
        <div v-for="item in list" :key="item" class="bm-lists">
          <div class="bm-listsA">
            心愿订单 - {{ item.Name }} - {{ item.StatusName }}
          </div>
          <div class="bm-listsB">
            <div>发 起 人 ： {{ item.Name }}</div>
            <div>联系电话： {{ item.Mobile }}</div>
            <div>订单地址： {{ item.Address }}</div>
            <div>订单时间： {{ item.AddTime }}</div>
            <div>订单需求： {{ item.Help }}</div>
          </div>
          <!-- <div class="button">
            <van-button type="primary">接单</van-button>
          </div> -->
        </div>
        <div style="text-align: center;margin: 15px 0px;" v-if="hidden">
        您还没有接过订单!
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WxGetHandlerDesireHelp } from "@/api/yeguang";
import { getOpenId } from "@/utils/auth";
export default {
  data() {
    return {
      pagelimin: { },
      activeNames: ["1"],
      list: [],
      hidden:false,
    };
  },
  created() {
    this.DesireHelpList();
  },
  methods: {
    DesireHelpList: function () {
      this.pagelimin.openID = getOpenId();
      WxGetHandlerDesireHelp(this.pagelimin).then((res) => {
        if(res.data.data.length == 0){
          this.hidden = true;
          this.list = [];
          return false;
        }
        this.list = res.data.data;
        for (var i = 0; i < this.list.length; i++) {
          if (this.list[i].Status == 1) {
            this.list[i].StatusName = "公示中";
          } else {
            this.list[i].StatusName = "已结束";
          }
        }
      });
    },
  },
};
</script>
<style>
.bianmin {
  -webkit-column-width: 202px;
  -moz-column-width: 202px;
  column-width: 202px;
  padding-bottom: 30px;
  background: #f8f8f8;
  height: 100%;
}
.bianmin .bm-lists {
  margin: 10px;
  background: #fff;
}
.bianmin .bm-listsA {
  padding: 8px 15px;
  border-bottom: 1px solid #f7f8fa;
}
.bianmin .bm-listsB div {
  padding: 2px 15px;
  font-size: 14px;
  color: #333;
}
.bianmin .button {
  display: inline-block;
  margin: 8px 15px;
}
.bianmin .button .van-button {
  height: 30px;
}
.bianmin .button span {
  color: #fff;
}
</style>